import React, { useEffect, useContext, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./app.css";
import Navbar from "components/Navbar/Navbar";
import Footer from "components/Footer/Footer";
import { HomePage } from "Pages/Home";
import { TourPage } from "Pages/Tour";
import { ContactPage } from "Pages/Contact";
import { AboutPage } from "Pages/About";
import { TourDetails } from "Pages/TourDetails";
import { LoginPage } from "Pages/Auth/Login";
import { DashboardPage } from "Pages/Dashboard/Dashboard";
import { BookingHistory } from "Pages/Dashboard/BookingHistory/BookingHistory";

import { AuthContext } from "Context/Auth";
import { BookNow } from "components/BookNow";

import { IoCallOutline } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";
import { TermsAndConditions } from "Pages/Terms";
import { Gallary } from "Pages/Gallary";
import { Profile } from "Pages/User/Profile";
// import { Reviews } from "components/Reviews";
// import { MdPhoneCallback } from "react-icons/md";

const App = () => {
  const { auth } = useContext(AuthContext);
  const [showBookNow, setShowBookNow] = useState(false);
  const [callBtn, setCallBtn] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleBookNowModal = () => {
    setShowBookNow(!showBookNow);
  };

  return (
    <>
      <Navbar handleOpenBookNow={toggleBookNowModal} />
      {showBookNow && <BookNow handleClose={toggleBookNowModal} />}

      {!callBtn && (
        <>
          <div onClick={toggleBookNowModal} className="GetcallbackIcon">
            <div>Get CallBack</div>
          </div>

          <div className="callnowIcon">
            <a href="tel:+91-9140565887">Call Now</a>
          </div>
        </>
      )}

      <div className="callbackIcon">
        {callBtn ? (
          <IoCallOutline onClick={() => setCallBtn(false)} className="icon" />
        ) : (
          <>
            <AiOutlineClose onClick={() => setCallBtn(true)} className="icon" />
          </>
        )}
      </div>
      <Routes>
        <Route path="/login" index element={<LoginPage />} />
        <Route path="/">
          <Route index element={<HomePage />} />
          <Route path="terms-and-condition" element={<TermsAndConditions />} />
          <Route path="gallary" element={<Gallary />} />
          <Route path="tours">
            <Route index element={<TourPage />} />
            <Route path=":tourId" element={<TourDetails />} />
          </Route>
          <Route path="contact">
            <Route index element={<ContactPage />} />
          </Route>
          <Route path="about">
            <Route index element={<AboutPage />} />
          </Route>
          <Route path="packages">
            <Route index element={<TourPage />} />
          </Route>
          {auth?.isAuthenticated && (
            <Route path="dashboard/bookings">
              <Route index element={<DashboardPage />} />
              <Route path=":status" element={<BookingHistory />} />
            </Route>
          )}
          {auth?.isAuthenticated && (
            <Route path="dashboard/user">
              <Route path="profile" element={<Profile />} />
              <Route path="change-password" element={<BookingHistory />} />
            </Route>
          )}
        </Route>
      </Routes>
      <Footer />
    </>
  );
};

export default App;
