import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsPatchCheck } from "react-icons/bs";
// import { calculateNights, convertDateToString } from "Pages/Dashboard/helper";

import "./booking-details.css";
import { BusInfo } from "./components/BusInfo";
import { CabInfo } from "./components/CabInfo";
import { CampInfo } from "./components/CampInfo";
import { HotelInfo } from "./components/HotelInfo";

export const BookingDetails = ({
  isOpen,
  handleClose,
  packageDetails,
  modalType,
}) => {
  return (
    <section className="bookingDetails">
      <div className="bookingDetailsContainer">
        <div className="topBarContainer">
          <div className="closeIcon">
            <AiOutlineCloseCircle onClick={handleClose} className="icon" />
          </div>
          <div className="topBarTitle">
            <div className="titleContainer">
              <h3>Thank you for travelling with us!</h3>
            </div>
            <div className="iconContainer">
              <BsPatchCheck />
            </div>
          </div>
        </div>

        {modalType === "HOTEL" &&
          packageDetails?.hotel_info?.length > 0 &&
          packageDetails?.hotel_info?.map((hotel, Idx) => {
            return (
              <HotelInfo
                key={Idx}
                addClass={Idx > 0 && "mr_top_10"}
                data={hotel}
                bookingId={packageDetails?._id}
              />
            );
          })}

        {modalType === "CAMP" &&
          packageDetails?.camp_info?.length > 0 &&
          packageDetails?.camp_info?.map((hotel, Idx) => {
            return (
              <CampInfo
                key={Idx}
                addClass={Idx > 0 && "mr_top_10"}
                data={hotel}
                bookingId={packageDetails?._id}
              />
            );
          })}

        {modalType === "BUS" &&
          packageDetails?.bus_info?.length > 0 &&
          packageDetails?.bus_info?.map((bus, Idx) => {
            return (
              <BusInfo
                key={Idx}
                addClass={Idx > 0 && "mr_top_10"}
                data={bus}
                bookingId={packageDetails?._id}
              />
            );
          })}

        {modalType === "CAB" &&
          packageDetails?.cab_info?.length > 0 &&
          packageDetails?.cab_info?.map((bus, Idx) => {
            return (
              <CabInfo
                key={Idx}
                addClass={Idx > 0 && "mr_top_10"}
                data={bus}
                bookingId={packageDetails?._id}
              />
            );
          })}
      </div>
    </section>
  );
};
