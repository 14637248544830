import React, { useEffect, useState } from "react";
import "./home.css";
import { FaRegHandPointDown } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import { AiOutlineInstagram } from "react-icons/ai";
import {
  AiFillYoutube,
  AiOutlineMail,
  AiOutlineMobile,
  AiOutlineMessage,
  AiOutlineUser,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { useAlert } from "react-alert";
import { createTicket } from "services";

import landing_image from "Assets/landing_image.jpeg";

import Aos from "aos";
import "aos/dist/aos.css";
import { LoaderText } from "components/LoaderText";

const Home = () => {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    const { data, error } = await createTicket(formData);
    if (data) {
      alert.show("Data Recorded Successfully!", {
        timeout: 3000,
        type: "success",
      });
      setLoading(false);
    }
    if (error) {
      Object.keys(error?.response?.data?.error).forEach((errors) => {
        alert.show(error?.response?.data?.error[errors], {
          timeout: 5000,
          type: "error",
        });
      });
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <section className="home">
      <div className="verlay"></div>
      <img alt="landing" className="landingImage" src={landing_image} />

      <div className="homeContent container">
        <div className="textDiv">
          <span data-aos="fade-up" className="smallText">
            Join Us To Meet True Nature
          </span>

          <h1 data-aos="fade-up" className="homeTitle">
            HIGH ON RIDES
          </h1>
        </div>

        <div data-aos="fade-up">
          <div className="inputConainerTitle">
            Get the best deals
            <FaRegHandPointDown className="icon" />
          </div>
          <div className="cardDiv grid">
            <div className="destinationInput">
              {/* <label htmlFor="name">Your Name:</label> */}
              <div className="input flex">
                <input
                  type="text"
                  name="name"
                  onChange={handleChange}
                  placeholder="Your name*"
                />
                <AiOutlineUser className="icon" />
              </div>
            </div>

            <div className="destinationInput">
              {/* <label htmlFor="name">Your Name:</label> */}
              <div className="input flex">
                <input
                  name="email"
                  onChange={handleChange}
                  type="text"
                  placeholder="Your email*"
                />
                <AiOutlineMail className="icon" />
              </div>
            </div>

            <div className="destinationInput">
              {/* <label htmlFor="name">Your Name:</label> */}
              <div className="input flex">
                <input
                  name="mobile"
                  onChange={handleChange}
                  type="text"
                  placeholder="Phone number*"
                />
                <AiOutlineMobile className="icon" />
              </div>
            </div>

            <div className="destinationInput">
              {/* <label htmlFor="name">Your Name:</label> */}
              <div className="input flex">
                <input
                  name="message"
                  onChange={handleChange}
                  type="text"
                  placeholder="message"
                />
                <AiOutlineMessage className="icon" />
              </div>
            </div>

            <div onClick={handleSubmit} className="searchOptions flex">
              {loading ? <LoaderText /> : <span>Submit</span>}
              <AiOutlineArrowRight className="icon" />
            </div>
          </div>
        </div>

        <div data-aos="fade-up" className="homeFooterIcons flex">
          <div className="rightIcons">
            <FiFacebook
              onClick={() =>
                window.open(`https://www.facebook.com/banjarey2020/`)
              }
              className="icon"
            />
            <AiOutlineInstagram
              onClick={() =>
                window.open(`https://www.instagram.com/__banjarey__`)
              }
              className="icon"
            />
            <AiFillYoutube
              onClick={() =>
                window.open(
                  `https://www.youtube.com/channel/UCtLZFSPJQ_T-Byhh56FV78Q`
                )
              }
              className="icon"
            />
          </div>

          <div className="leftIcons">
            {/* <BsListOl className="icon" />
            <TbApps className="icon" /> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
