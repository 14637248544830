import React from "react";

import "./gallary.css";

import { gallary_images } from "constants/images_gallary";

const { REACT_APP_BASE_IMG_PATH } = process.env;

const newImgArr = gallary_images.tree
  .sort(() => Math.random() - Math.random())
  .slice(0, 19);

export const Gallary = () => {
  // console.log(newImgArr.chunk_inefficient(5));
  const chunkSize = 6;
  const res = [];
  for (let i = 0; i < newImgArr.length; i += chunkSize) {
    const chunk = newImgArr.slice(i, i + chunkSize);
    res.push(chunk);
  }

  console.log('res', res);

  return (
    <section className="gallary section">
      <div className="gallaryTitle">Image Gallary</div>

      <div className="row">
        {res.map((imgData, Idx) => {
          return (
            <div className="column" key={Idx}>
              {imgData.map((imgObj, imgIdx) => {
                return (
                  <img
                    key={imgObj.path}
                    alt="user"
                    src={REACT_APP_BASE_IMG_PATH + "/" + imgObj.path}
                  />
                );
              })}
            </div>
          );
        })}
        <div className="column">
          <img
            alt="user"
            src={REACT_APP_BASE_IMG_PATH + "/" + gallary_images.tree[0].path}
            style={{ width: "100%" }}
          />
          {/* <img src="/w3images/rocks.jpg" style="width:100%" />
          <img src="/w3images/falls2.jpg" style="width:100%" />
          <img src="/w3images/paris.jpg" style="width:100%" />
          <img src="/w3images/nature.jpg" style="width:100%" />
          <img src="/w3images/mist.jpg" style="width:100%" />
          <img src="/w3images/paris.jpg" style="width:100%" /> */}
        </div>
        {/* <div class="column">
          <img src="/w3images/underwater.jpg" style="width:100%" />
          <img src="/w3images/ocean.jpg" style="width:100%" />
          <img src="/w3images/wedding.jpg" style="width:100%" />
          <img src="/w3images/mountainskies.jpg" style="width:100%" />
          <img src="/w3images/rocks.jpg" style="width:100%" />
          <img src="/w3images/underwater.jpg" style="width:100%" />
        </div>
        <div class="column">
          <img src="/w3images/wedding.jpg" style="width:100%" />
          <img src="/w3images/rocks.jpg" style="width:100%" />
          <img src="/w3images/falls2.jpg" style="width:100%" />
          <img src="/w3images/paris.jpg" style="width:100%" />
          <img src="/w3images/nature.jpg" style="width:100%" />
          <img src="/w3images/mist.jpg" style="width:100%" />
          <img src="/w3images/paris.jpg" style="width:100%" />
        </div>
        <div class="column">
          <img src="/w3images/underwater.jpg" style="width:100%" />
          <img src="/w3images/ocean.jpg" style="width:100%" />
          <img src="/w3images/wedding.jpg" style="width:100%" />
          <img src="/w3images/mountainskies.jpg" style="width:100%" />
          <img src="/w3images/rocks.jpg" style="width:100%" />
          <img src="/w3images/underwater.jpg" style="width:100%" />
        </div> */}
      </div>
    </section>
  );
};
