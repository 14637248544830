import {
  GET_PACKAGES_FAILED,
  GET_PACKAGES_START,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGE_START,
  GET_PACKAGE_SUCCESS,
  GET_PACKAGE_FAILED,
  GET_USER_PACKAGE_STAT_FAILED,
  GET_USER_PACKAGE_STAT_SUCCESS,
  GET_USER_PACKAGE_STAT_START,
  GET_USER_PACKAGE_START,
  GET_USER_PACKAGE_SUCCESS,
  GET_USER_PACKAGE_FAILED,
} from "constants/reducer-types";

const PackageReducer = (packageState, action) => {
  switch (action.type) {
    case GET_PACKAGES_START: {
      return {
        ...packageState,
        loading: true,
        error: null,
      };
    }
    case GET_PACKAGES_SUCCESS: {
      return {
        ...packageState,
        loading: false,
        error: null,
        packages: action.payload,
      };
    }
    case GET_PACKAGES_FAILED: {
      return {
        ...packageState,
        loading: false,
        error: action.payload,
      };
    }
    case GET_PACKAGE_START: {
      return {
        ...packageState,
        loading: true,
        error: null,
      };
    }
    case GET_PACKAGE_SUCCESS: {
      return {
        ...packageState,
        loading: false,
        error: null,
        package: action.payload,
      };
    }
    case GET_PACKAGE_FAILED: {
      return {
        ...packageState,
        loading: false,
        error: action.payload,
      };
    }
    case GET_USER_PACKAGE_STAT_START: {
      return {
        ...packageState,
        loading: true,
        error: null,
      };
    }
    case GET_USER_PACKAGE_STAT_SUCCESS: {
      return {
        ...packageState,
        userPackageStats: action.payload,
        loading: false,
        error: null,
      };
    }
    case GET_USER_PACKAGE_STAT_FAILED: {
      return {
        ...packageState,
        loading: false,
        error: action.payload,
      };
    }
    case GET_USER_PACKAGE_START: {
      return {
        ...packageState,
        loading: true,
        error: null,
      };
    }
    case GET_USER_PACKAGE_SUCCESS: {
      return {
        ...packageState,
        loading: false,
        error: null,
        userPackages: action.payload,
      };
    }
    case GET_USER_PACKAGE_FAILED: {
      return {
        ...packageState,
        loading: false,
        error: action.payload,
      };
    }
    default:
      return packageState;
  }
};

export default PackageReducer;
