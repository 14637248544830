import React from "react";
import { IoCallOutline } from "react-icons/io5";
import { calculateNights, convertDateToString } from "Pages/Dashboard/helper";

export const CampInfo = ({ data, bookingId, addClass }) => {
  return (
    <div className={`campDetailsContainer ${addClass}`}>
      <div className="bookingId">
        Booking ID: <span>{bookingId?.toUpperCase()}</span>
      </div>
      <div className="campDetails">
        <div className="campName">{data?.camp_name}</div>
        <div className="campAddress">
          {/* 63/13-18, The Mall Road, Near Maa Kali Mandir */}
          {/* <br /> */}
          {/* Murrey Compny Bridge, Kanpur */}
        </div>
      </div>

      <div className="checkInCheckOut">
        <div className="checkIn">
          <div>Check-in</div>
          <div>{convertDateToString(data?.check_in_date).substring(0, 10)}</div>
        </div>
        <div className="daysCount">
          -{calculateNights(data?.check_in_date, data?.check_out_date)}
          N-
        </div>
        <div className="checkOut">
          <div>Checkout</div>
          <div>
            {convertDateToString(data?.check_out_date).substring(0, 10)}
          </div>
        </div>
      </div>

      <div className="guestDetails">
        <div className="guestNames">
          {data?.guest_info?.[0]?.name}{" "}
          {data?.guest_info?.length - 1 > 0 &&
            `+ ${data?.guest_info?.length - 1}`}
        </div>
        <div className="roomType">
          {data?.camp_room_type} · {data?.camp_rooms} Room · {data?.guest_info?.length}{" "}
          Guest
        </div>
      </div>

      {data?.contact_no && (
        <div className="camp_contact_info">
        <div className="call_info">
          <a href={`tel:${data?.contact_no}`}>
            <IoCallOutline className="icon" />
          </a>
          <div>
            <span>Call Camp</span>
          </div>
        </div>
      </div>
      )}

    </div>
  );
};
