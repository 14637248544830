import * as CryptoJS from "crypto-js";

const { REACT_APP_ENCRYPTION_KEY } = process.env;

export const decryptString = (ciphertext) => {
    const bytes  = CryptoJS.AES.decrypt(ciphertext, REACT_APP_ENCRYPTION_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
}

export const decryptObject = (cipherObj) => {
    const bytes  = CryptoJS.AES.decrypt(cipherObj, REACT_APP_ENCRYPTION_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}
