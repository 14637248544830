import Page from "components/Page";
import React, { useEffect } from "react";

import "./terms.css";

export const TermsAndConditions = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <Page title="Terms And Condition | Tour Packages | Honeymoon Packages | Group Tours | Corporate Trips | Homestays | Solo Trips">
      <section className="terms section">
        <div className="termsMainContainer">
          <div className="contentTitle">
            <h2>Terms and condition</h2>
          </div>
          <div className="termsListContainer">
            <ul className="termsContent">
              <li className="termsList">
                We do not have insurance policy covering the expenses for
                accident, sickness, loss due to theft or any other reason. Guest
                are advised to seek such Insurance in advance.
              </li>
              <li className="termsList">
                We request not to travel with heavy luggage. Factors such as
                weather, road conditions, physical abilities of the participants
                etc.. may dictate itinerary change. We reserve the right to
                change any schedule in interest of safety, comfort and general
                well-being.
              </li>
              <li className="termsList">
                We do not allow smoking during the travel in bus.
              </li>
              <li className="termsList">
                Banjarey Travel Partner reserve the right to discontinue your
                trip at any point on the grounds of misconduct and no refund
                shall be made under such circumstances.
              </li>
              <li className="termsList">
                Departure time is fixed as given and its on you to update your
                status to the trip coordinator. Trip coordinator will also try
                to contact you at least 2 times but Banjarey Travel Partner is
                not responsible for phone not getting connected.
              </li>
              <li className="termsList">
                The person making the reservation is responsible for any damage
                caused to room/camp/resort furnishing and is liable to pay for
                the same.
              </li>
              <li className="termsList">
                Please note that after the finalization of the Tour/Service
                Cost, if there are any Hike in entrance fees of
                Monuments/museums, taxes, fuel cost or guide charges - by Govt
                of India, the same would be charged as extra.
              </li>
              <li className="termsList">
                In the hilly terrain, it will be the discretion of the driver to
                put off the systems of the vehicle like Air Conditioner, sound
                system. Focus will always be on safety.
              </li>
              <li className="termsList">
                In case of any injuries/illness during the trip/activity,
                Banjarey Travel Partner as a Tour operator or any of our local
                staff will not be responsible.
              </li>
              <li className="termsList">
                No act of misconduct or indiscipline shall be tolerated on the
                tours. We are a cordial travel community and we aspire to bring
                to you a hassle free and memorable experience.
              </li>
              <li className="termsList">
                It is understood that any expenses (eg. Hotel, means, transport,
                telephone calls, repairing of vehicle etc) relating to the
                unscheduled extensions like natural calamity, break down of
                vehicle, any other delays which are beyond our control will be
                bear by the passengers and no refund will be given in any
                scenario.
              </li>
              <li className="termsList">
                We reserve the rights for the cancellation of a particular
                person not following the instructions of our guide during any
                activity.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </Page>
  );
};
