import React from "react";
import { MdAirlineSeatReclineExtra } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import { TbBus } from "react-icons/tb";
import { convertDateToString } from "Pages/Dashboard/helper";

export const BusInfo = ({ data, bookingId, addClass }) => {
  return (
    <div className={`busDetailsContainer ${addClass}`}>
      <div className="bookingId">
        Booking ID: <span>{bookingId?.toUpperCase()}</span>
      </div>
      <div className="busDetails">
        <div className="busNumber">
          <b>{data?.bus_number}</b>
        </div>
        <div className="journeyDate">
          {convertDateToString(data?.journey_date)}
        </div>
      </div>

      <div className="busTravelInfo">
        <div className="from">{data?.from}</div>
        <div>
          <TbBus className="icon" />
        </div>
        <div className="to">{data?.to}</div>
      </div>

      <div className="seatInfo">
        <div className="iconContainer">
          Seat Number
          <MdAirlineSeatReclineExtra className="icon" />
        </div>
        <div className="seatNumber">
          {data?.seat_number.split(",").map((seat) => (
            <p key={seat}>{seat}</p>
          ))}
        </div>
      </div>

      <div className="seatInfo">
        <div className="seatNumber">
          {data?.passenger_info.map((user) => (
            <p key={user?.name}>{user?.name}</p>
          ))}
        </div>
      </div>

      <div className="bus_contact_info">
        <div className="call_info">
          <a href={`tel:${data?.bus_coordinator_no}`}>
            <IoCallOutline className="icon" />
          </a>
          <div>
            <span>Call Bus</span>
          </div>
        </div>
      </div>
    </div>
  );
};
