import React, { useState } from "react";
import {
  AiOutlinePhone,
  AiOutlineMail,
  AiOutlineMessage,
  AiFillCloseCircle,
} from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";
import { FiSend } from "react-icons/fi";
import { useAlert } from "react-alert";

import { createTicket } from "services";

import "./book-now.css";
import { LoaderText } from "components/LoaderText";

export const BookNow = ({ handleClose }) => {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const handleSubmit = async () => {
    setLoading(true);
    const { data, error } = await createTicket(formData);
    if (data) {
      alert.show("Data Recorded Successfully!", {
        timeout: 2000,
        type: "success",
      });
      setLoading(false);
      handleClose();
    }
    if (error) {
      setLoading(false);
      Object.keys(error?.response?.data?.error).forEach((errors) => {
        alert.show(error?.response?.data?.error[errors], {
          timeout: 5000,
          type: "error",
        });
      });
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <section className="bookNowContainer">
      <div className="bookNowForm">
        <div className="bookNowFormContainer">
          <div className="inputContainer">
            <label htmlFor="Name">Name:</label>
            <div className="input flex">
              <input
                type="text"
                onChange={handleChange}
                name="name"
                placeholder="Enter name here...."
              />
              <BiUserCircle className="icon" />
            </div>
          </div>

          <div className="inputContainer">
            <label htmlFor="Name">Email:</label>
            <div className="input flex">
              <input
                type="text"
                onChange={handleChange}
                name="email"
                placeholder="Enter email here...."
              />
              <AiOutlineMail className="icon" />
            </div>
          </div>

          <div className="inputContainer">
            <label htmlFor="Name">Mobile:</label>
            <div className="input flex">
              <input
                type="text"
                onChange={handleChange}
                name="mobile"
                placeholder="Enter mobile here...."
              />
              <AiOutlinePhone className="icon" />
            </div>
          </div>

          <div className="inputContainer">
            <label htmlFor="Name">Message:</label>
            <div className="input flex">
              <textarea
                type="text"
                onChange={handleChange}
                name="message"
                placeholder="Enter message here...."
              />
              <AiOutlineMessage className="icon" />
            </div>
          </div>

          <div className="inputContainer">
            <div onClick={handleSubmit} className="sendButton flex">
              <FiSend className="icon" />
              {loading ? <LoaderText /> : <span>Send</span>}
            </div>
          </div>
        </div>

        <div className="closeForm">
          <AiFillCloseCircle onClick={handleClose} className="icon" />
        </div>
      </div>
    </section>
  );
};
