/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { RxDashboard } from "react-icons/rx";
import { IoArrowBackOutline } from "react-icons/io5";
import { TbGridDots } from "react-icons/tb";
import { AuthContext } from "Context/Auth";
import {
  AiOutlineWhatsApp,
  AiOutlineInstagram,
  AiOutlineLogin,
  AiOutlineLogout,
} from "react-icons/ai";
// import { RiLockPasswordLine } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { FiYoutube } from "react-icons/fi";
import { IoCallOutline } from "react-icons/io5";
import "./navbar.css";

import Aos from "aos";
import "aos/dist/aos.css";

const Navbar = ({ handleOpenBookNow }) => {
  const location = useLocation();
  const currentAddress = location.pathname.split("/");
  const { REACT_APP_WHATSAPP_URI } = process.env;
  const { auth, logOutUser } = useContext(AuthContext);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const [active, setActive] = useState("navBar");
  // Function to togggle navbar
  const showNavbar = () => {
    setActive("navBar activeNavbar");
  };

  // Function to remove navbar
  const removeNavbar = () => {
    setActive("navBar");
  };

  const signOut = () => {
    removeNavbar();
    logOutUser();
  };

  const handleInquiryForm = () => {
    removeNavbar();
    handleOpenBookNow();
  };

  return (
    <section className="navbarSection">
      <div className="infoNavBar">
        <div className="mobile">
          <a href="tel:+91-9140565887">
            <IoCallOutline />
            +91-9140565887
          </a>
        </div>
        <div className="socialIcons">
          <AiOutlineWhatsApp
            onClick={() =>
              window.open(
                `${REACT_APP_WHATSAPP_URI}?text=Hi, I want to know more about Packages`
              )
            }
          />
          <AiOutlineInstagram
            onClick={() =>
              window.open(`https://www.instagram.com/__banjarey__`)
            }
          />
          <FiYoutube
            onClick={() =>
              window.open(
                `https://www.youtube.com/channel/UCtLZFSPJQ_T-Byhh56FV78Q`
              )
            }
          />
        </div>
      </div>
      <header className="header flex">
        <div className="logoDiv flex">
          <Link to="/" className="logo flex">
            <img
              style={{ width: 75, height: 75 }}
              src="https://raw.githubusercontent.com/anujsinghwd/banjarey-images/main/0001-3352367735_20210624_123322_0000-removebg-preview.png"
              alt="logo"
            />
            <h1 style={{ fontSize: 20, color: "white" }}>
              Banjarey Travel Partner
            </h1>
          </Link>
        </div>

        <div className={active}>
          <ul className="navLists flex">
            <li onClick={removeNavbar} className="navItem">
              <Link to="/" className="navLink">
                Home
              </Link>
            </li>
            {/* <Link to="/tours"> */}
            <li onClick={removeNavbar} className="navItem">
              <Link className="navLink" to="/tours">
                Packages
              </Link>
            </li>
            <li onClick={removeNavbar} className="navItem">
              <Link to="/contact" className="navLink">
                Contact
              </Link>
            </li>
            <li onClick={removeNavbar} className="navItem">
              <Link to="about" className="navLink">
                About Us
              </Link>
            </li>
            {/* <li onClick={removeNavbar} className="navItem">
              <Link to="gallary" className="navLink">
                Gallary
              </Link>
            </li> */}

            {auth?.isAuthenticated && (
              <li onClick={removeNavbar} className="navItem">
                <Link to="/dashboard/bookings" className="navLink">
                  Dashboard
                </Link>
              </li>
            )}

            {auth?.isAuthenticated ? (
              <li onClick={signOut} className="navItem">
                <Link to="" className="navLink flex">
                  <AiOutlineLogout />
                  Logout
                </Link>
              </li>
            ) : (
              <li onClick={removeNavbar} className="navItem">
                <Link to="/login" className="navLink flex">
                  <AiOutlineLogin />
                  Login
                </Link>
              </li>
            )}

            <button onClick={handleInquiryForm} className="btn">
              <a href="#">BOOK NOW</a>
            </button>
          </ul>

          <div className="closeNavbar" onClick={removeNavbar}>
            <AiFillCloseCircle className="icon" />
          </div>
        </div>

        <div className="toggleNavbar" onClick={showNavbar}>
          <TbGridDots className="icon" />
        </div>
      </header>
      {auth?.isAuthenticated && (
        <div className="authNavBar flex">
          {currentAddress.includes("user") && (
            <Link className="profile-back-btn" to="dashboard/bookings">
              <IoArrowBackOutline className="icon" />
            </Link>
          )}
          {!currentAddress.includes("profile") && currentAddress.includes("dashboard") && (
            <Link to="/dashboard/user/profile" className="navLink">
              <div className="profileSection">
                <CgProfile />
                Profile
              </div>
            </Link>
          )}

          {!currentAddress.includes("dashboard") &&
            !currentAddress.includes("bookings") && (
              <Link to="/dashboard/bookings" className="navLink">
                <div className="profileSection">
                  <RxDashboard />
                  Dashboard
                </div>
              </Link>
            )}
          {/* {currentAddress.includes("dashboard") &&
            currentAddress.includes("bookings") && (
              <div className="profileSection">
                <RiLockPasswordLine />
                Change Password
              </div>
            )} */}
        </div>
      )}
    </section>
  );
};

export default Navbar;
