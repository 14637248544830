import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { HiOutlineClipboardCheck } from "react-icons/hi";

import Aos from "aos";
import "aos/dist/aos.css";

import "./main.css";
// import { Loader } from "components/Loader/Loader";

const Main = ({ listData = [], title, detailPageUrl = null }) => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <section className="main container section">
      <div className="secTitle">
        <h3 data-aos="fade-right" className="title">
          {title}
        </h3>
      </div>

      {/* {listData.length === 0 && <Loader />} */}

      <div className="secContent grid">
        {listData?.length > 0 &&
          listData?.map((list) => {
            return (
              <div
                data-aos="fade-up"
                key={list?.place_id}
                className="singleDestination"
              >
                <div className="imageDiv">
                  <img
                    src={list?.tour_images[0]}
                    alt={`tour-${list?.place_id}`}
                  />
                </div>

                <div className="cardInfo">
                  <span className="continent flex">
                    <HiOutlineLocationMarker className="icon" />
                    <span className="name">{list?.title}</span>
                  </span>

                  <div className="fees flex">
                    <div className="grade">
                      <span>Starting Price</span>
                    </div>
                    <div className="price">
                      <h5>₹ {list?.starting_range}</h5>
                    </div>
                  </div>

                  <div className="desc">
                    <p>{`${list?.overview.slice(0, 120)}...`}</p>
                  </div>

                  <Link
                    to={detailPageUrl ?  `${detailPageUrl}/${list?.place_id}` : `${list?.place_id}`}
                    className="btn flex"
                  >
                    DETAILS <HiOutlineClipboardCheck className="icon" />
                  </Link>
                </div>
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default Main;
