import React from "react";
import { calculateNights, convertDateToString } from "Pages/Dashboard/helper";

export const HotelInfo = ({ data, bookingId, addClass }) => {
  return (
    <div className={`hotelDetailsContainer ${addClass}`}>
      <div className="bookingId">
        Booking ID: <span>{bookingId?.toUpperCase()}</span>
      </div>
      <div className="hotelDetails">
        <div className="hotelName">{data?.hotel_name}</div>
        <div className="hotelAddress">
          {/* 63/13-18, The Mall Road, Near Maa Kali Mandir */}
          {/* <br /> */}
          {/* Murrey Compny Bridge, Kanpur */}
        </div>
      </div>

      <div className="checkInCheckOut">
        <div className="checkIn">
          <div>Check-in</div>
          <div>{convertDateToString(data?.check_in_date).substring(0, 10)}</div>
        </div>
        <div className="daysCount">
          -{calculateNights(data?.check_in_date, data?.check_out_date)}
          N-
        </div>
        <div className="checkOut">
          <div>Checkout</div>
          <div>
            {convertDateToString(data?.check_out_date).substring(0, 10)}
          </div>
        </div>
      </div>

      <div className="guestDetails">
        <div className="guestNames">
          {data?.guest_info?.[0]?.name}{" "}
          {data?.guest_info?.length - 1 > 0 &&
            `+ ${data?.guest_info?.length - 1}`}
        </div>
        <div className="roomType">
          {data?.room_type} · {data?.rooms} Room · {data?.guest_info?.length}{" "}
          Guest
        </div>
      </div>
    </div>
  );
};
