import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { HelmetProvider } from "react-helmet-async";
import { CookiesProvider } from "react-cookie";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { AppContextProvider } from "Context/App";
import { PackageContextProvider } from "Context/Package";
import { AuthProvider } from "Context/Auth";
import history from "custom-history";

import * as serviceWorkerRegistration from "serviceWorkerRegistration";


// optional configuration
const options = {
  // offset: '5px'
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: "10px",
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 4000
  }
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter history={history}>
    <CookiesProvider>
      <HelmetProvider>
        <AppContextProvider>
          <AuthProvider>
            <PackageContextProvider>
              <AlertProvider template={AlertTemplate} {...options}>
                <App />
              </AlertProvider>
            </PackageContextProvider>
          </AuthProvider>
        </AppContextProvider>
      </HelmetProvider>
    </CookiesProvider>
  </BrowserRouter>
);

serviceWorkerRegistration.register();
