import React, { useEffect, useContext } from "react";
import Home from "components/Home/Home";
import Main from "components/Main/Main";
import { PackageContext } from "Context/Package";
import {
  GET_PACKAGES_START,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_FAILED,
} from "constants/reducer-types";
import { readVisitedDestinations } from "services";
import Page from "components/Page";
import { Loader } from "components/Loader/Loader";
import { Reviews } from "components/Reviews";

import "./home-page.css";

export const HomePage = () => {
  const { packageDispatch, packages, packageLoading } =
    useContext(PackageContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      packageDispatch({ type: GET_PACKAGES_START });
      const { data, error } = await readVisitedDestinations();
      if (data) {
        packageDispatch({ type: GET_PACKAGES_SUCCESS, payload: data?.data });
      }
      if (error) {
        packageDispatch({ type: GET_PACKAGES_FAILED, payload: error });
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="BANJAREY TRAVEL PARTNER - Honeymoon Packages | Group Tours | Corporate Trips | Homestays | Solo Trips">
      <div className="section">
      <Home />
      {packageLoading ? (
        <div className="loaderConfig">
          <Loader />
        </div>
      ) : (
        <Main
          detailPageUrl="tours"
          listData={packages.slice(0, 6)}
          title="Most visited destinations"
        />
      )}
      <div className="reviews">
        <div className="reviewTitle">
          <h3 data-aos="fade-right">
            Our Google Reviews
          </h3>
        </div>
        <Reviews />
      </div>
      </div>
    </Page>
  );
};
