import { useState, createContext, useMemo, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { loginUser } from "services";
import { setAuthToken } from "utils/set-auth-token";

export const AuthContext = createContext();

export const AuthProvider = (props) => {
  const [cookies, setCookies, removeCookie] = useCookies();
  const navigate = useNavigate();

  const [auth, setAuth] = useState({
    isAuthenticated: false,
    user_data: {},
    authLoading: false,
    error: null,
  });

  const handleLoginUser = async (email = null, password = null) => {
    setAuth({ ...auth, authLoading: true, error: null });
    const { data, error } = await loginUser(email, password);
    const token = data?.data?.accessToken;
    if (token) {
      const user = jwtDecode(token);
      setAuthToken(token);
      setAuth({
        ...auth,
        isAuthenticated: true,
        user_data: user,
        authLoading: false,
        error: null,
      });
      setCookies("token", token);
      setCookies("user", user);
      navigate("/dashboard/bookings", { replace: true });
    }

    if (error) {
      setAuth({
        ...auth,
        error: error?.response?.data?.message,
        authLoading: false,
      });
    }
  };

  const logOutUser = () => {
    setAuth({ ...auth, isAuthenticated: false, user_data: {} });
    ["token", "user"].forEach((obj) => removeCookie(obj));

    navigate("/", { replace: true });
  };

  const autoLogin = () => {
    if (cookies?.token && cookies?.user) {
      setAuthToken(cookies?.token);
      setAuth({ ...auth, isAuthenticated: true, user_data: cookies?.user });
    }
  };

  useEffect(() => {
    autoLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authValues = useMemo(
    () => ({ logOutUser, loginUser: handleLoginUser, auth, cookies }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cookies, auth]
  );

  return (
    <AuthContext.Provider value={authValues}>
      {props.children}
    </AuthContext.Provider>
  );
};
