import axios from "axios";
const { REACT_APP_USER_SERVICES_BASE_URL } = process.env;

export const createTicket = async ({ name, email, mobile, message }) => {
  const resp = {
    data: null,
    error: null,
  };

  const reqPayload = {
    name,
    email,
    mobile,
    message,
  };

  try {
    const respData = await axios.post(
      `${REACT_APP_USER_SERVICES_BASE_URL}/btp-inquiry/create_inquiry`,
      reqPayload
    );
    resp.data = respData?.data;
  } catch (err) {
    resp.error = err;
  }
  return resp;
};
