import axios from "axios";

export const setAuthToken = (token) => {
  if (token) {
    // Apply to every Request
    axios.defaults.headers.common["x-access-token"] = token;
  } else {
    // Delete AuthHeader
    delete axios.defaults.headers.common["x-access-token"];
  }
};

