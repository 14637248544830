import React, { useEffect, useState } from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import {
  AiOutlinePhone,
  AiOutlineMail,
  AiOutlineMessage,
} from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";
import { FiSend } from "react-icons/fi";
import { useAlert } from "react-alert";
import { createTicket } from "services";

import Aos from "aos";
import "aos/dist/aos.css";
import "./contact.css";

import Page from "components/Page";
import { LoaderText } from "components/LoaderText";

export const ContactPage = () => {
  const alert = useAlert();
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 2000 });
  }, []);

  const handleSubmit = async () => {
    setLoader(true);
    const { data, error } = await createTicket(formData);
    if (data) {
      alert.show("Data Recorded Successfully!", {
        timeout: 3000,
        type: "success",
      });
      setLoader(false);
    }
    if (error) {
      Object.keys(error?.response?.data?.error).forEach((errors) => {
        alert.show(error?.response?.data?.error[errors], {
          timeout: 5000,
          type: "error",
        });
      });
      setLoader(false);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Page title="Contact Us | Tour Packages | Honeymoon Packages | Group Tours | Corporate Trips | Homestays | Solo Trips">
      <section className="contact section">
        <div className="contactContent">
          <h2>Contact Us</h2>
          {/* <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content
            here', making it look like readable English. Many desktop publishing
            packages and web page editors now use Lorem Ipsum as their default
            model text, and a search for 'lorem ipsum' will uncover many web
            sites still in their infancy.
          </p> */}
        </div>

        <div className="contactContainer">
          <div className="contactInfo" data-aos="fade-up">
            <div className="box">
              <div className="iconContainer">
                <HiOutlineLocationMarker className="icon" />
              </div>
              <div className="text">
                <h3>Address</h3>
                <p>
                  Banjarey travel partner, <br />
                  Ground floor, Vyamshala gym building
                  <br />
                  Bargadi, Bakshi Ka Talab, Lucknow, Uttar Pradesh 226201
                </p>
              </div>
            </div>

            <div className="box">
              <div className="iconContainer">
                <AiOutlinePhone className="icon" />
              </div>
              <div className="text">
                <h3>Phone</h3>
                <p>
                  +91-9140565887
                  <br />
                  +91-8890205502
                </p>
              </div>
            </div>

            <div className="box">
              <div className="iconContainer">
                <AiOutlineMail className="icon" />
              </div>
              <div className="text">
                <h3>Email</h3>
                <p>
                  contact@banjareytravelpartner.com
                  <br />
                  booking@banjareytravelpartner.com
                </p>
              </div>
            </div>
          </div>

          <div data-aos="fade-up" className="contactForm">
            <div className="inputContainer">
              <label htmlFor="Name">Name:</label>
              <div className="input flex">
                <input
                  name="name"
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter name here...."
                />
                <BiUserCircle className="icon" />
              </div>
            </div>

            <div className="inputContainer">
              <label htmlFor="Name">Email:</label>
              <div className="input flex">
                <input
                  name="email"
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter email here...."
                />
                <AiOutlineMail className="icon" />
              </div>
            </div>

            <div className="inputContainer">
              <label htmlFor="Name">Mobile:</label>
              <div className="input flex">
                <input
                  name="mobile"
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter mobile here...."
                />
                <AiOutlinePhone className="icon" />
              </div>
            </div>

            <div className="inputContainer">
              <label htmlFor="Name">Message:</label>
              <div className="input flex">
                <textarea
                  name="message"
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter message here...."
                />
                <AiOutlineMessage className="icon" />
              </div>
            </div>

            <div className="inputContainer">
              <div onClick={handleSubmit} className="sendButton flex">
                <FiSend className="icon" />
                {loader ? <LoaderText /> : <span>Send</span>}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
};
