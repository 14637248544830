export const quotes = [
    `Jobs fill your pockets, adventures fill your soul.`,
    `Remember that happiness is a way of travel, not a destination.`,
    ` The world is a book and those who do not travel read only one page.`,
    `Travel is the only thing you buy that makes you richer.`,
    `In the end, we only regret the chances we didn’t take`,
    `My goal is to run out of pages in my passport.`,
    `Not all those who wander are lost.`,
    `Travel is an investment in yourself.`,
    `Life is short, and the world is wide.`,
    `It’s not what you look at that matters. It’s what you see.`,
    `The goal is to die with memories not dreams.`,
    `Sandy Toes sunkissed nose.`,
    `Travel. Your money will return. Your time won’t.`,
    `Some beautiful paths can’t be discovered without getting lost.`,
    `Collect Moments, Not Things.`,
    `Live life with no excuses, travel with no regret.`,
    `Once the Travel bug bites there is no known antidote, and I know that I shall be happily infected until the end of my life.`,
    `We have nothing to lose and a world to see.`,
    `We don’t need objects; we need adventures.`,
    `Once a year, go someplace you’ve never been before.`
];
