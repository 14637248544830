import axios from "axios";
import { encryptString } from "helpers/encryption";
import { decryptObject } from "helpers/decryption";
const { REACT_APP_API_URL, REACT_APP_USER_SERVICES_BASE_URL } = process.env;

export const readVisitedDestinations = async (page = 0) => {
  const resp = {
    data: null,
    error: null,
  };

  try {
    const respData = await axios.get(`${REACT_APP_API_URL}/btp/tours/${page}`, {
      headers: {
        key: encryptString("getTours"),
      },
    });
    resp.data = decryptObject(respData?.data);
  } catch (err) {
    resp.error = err;
  }
  return resp;
};

export const getTour = async (id) => {
  const resp = {
    data: null,
    error: null,
  };
  try {
    const respData = await axios.get(`${REACT_APP_API_URL}/btp/tour/${id}`, {
      headers: {
        key: encryptString("getTourById"),
      },
    });
    resp.data = decryptObject(respData?.data);
  } catch (err) {
    resp.error = err;
  }
  return resp;
};

export const getUserPackageStats = async () => {
  const resp = {
    data: null,
    error: null,
  };
  try {
    resp.data = await axios.get(
      `${REACT_APP_USER_SERVICES_BASE_URL}/btp-packages/read_stats`
    );
  } catch (err) {
    resp.error = err;
  }
  return resp;
};

export const getUserPackages = async (Ids = []) => {
  const reqPayload = {
    ids: Ids,
  };

  const resp = {
    data: null,
    error: null,
  };
  try {
    resp.data = await axios.post(
      `${REACT_APP_USER_SERVICES_BASE_URL}/btp-packages/read_packages`,
      reqPayload
    );
  } catch (err) {
    resp.error = err;
  }
  return resp;
};
