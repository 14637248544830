import React, { useContext } from "react";
import { BsGenderFemale, BsGenderMale } from "react-icons/bs";
import {
  AiOutlineUser,
  AiOutlineMail,
  AiOutlineWhatsApp,
  AiOutlineMobile,
} from "react-icons/ai";
import { AuthContext } from "Context/Auth";
import manProfileImg from "Assets/profile/man.png";
import womenProfileImg from "Assets/profile/woman.png";
import "./profile.css";

export const Profile = () => {
  const { auth } = useContext(AuthContext);
  return (
    <section className="profile section">
      {/* <div className="secTitle">
        <h2>User Profile</h2>
      </div> */}

      <div className="profileContainer">
        <div className="userImageSection">
          <div className="image">
            <img
              src={auth?.user_data?.gender === "F" ? womenProfileImg : manProfileImg}
              className="user-profile"
              alt="ProfileImage"
            />
          </div>
          <div className="emailInfo">
            <div className="user-name">{auth?.user_data?.name}</div>
            <div className="user-email">{auth?.user_data?.email}</div>
          </div>
        </div>
        <div className="userInfoSection">
          <div className="cardDiv flex">
            <div className="userInput">
              <div className="input flex">
                <input
                  type="text"
                  name="name"
                  onChange={() => {}}
                  placeholder="Name"
                  disabled
                  value={auth?.user_data?.name}
                />
                <AiOutlineUser className="icon" />
              </div>
            </div>
            <div className="userInput">
              <div className="input flex">
                <input
                  type="text"
                  name="email"
                  disabled
                  placeholder="Email"
                  value={auth?.user_data?.email}
                />
                <AiOutlineMail className="icon" />
              </div>
            </div>

            <div className="userInput">
              <div className="input flex">
                <input
                  type="text"
                  name="gender"
                  disabled
                  onChange={() => {}}
                  placeholder="Gender"
                  value={auth?.user_data?.gender === "M" ? "Male" : "Female"}
                />
                {auth?.user_data?.gender === "M" ? (
                  <BsGenderMale className="icon" />
                ) : (
                  <BsGenderFemale className="icon" />
                )}
              </div>
            </div>

            <div className="userInput">
              <div className="input flex">
                <input
                  type="text"
                  name="whatsapp_no"
                  disabled
                  onChange={() => {}}
                  placeholder="WhatsApp No"
                  value={auth?.user_data?.whatsapp_no}
                />
                <AiOutlineWhatsApp className="icon" />
              </div>
            </div>

            <div className="userInput">
              <div className="input flex">
                <input
                  type="text"
                  name="mobile"
                  disabled
                  onChange={() => {}}
                  placeholder="Mobile No"
                  value={auth?.user_data?.mobile}
                />
                <AiOutlineMobile className="icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
