import React from "react";
import { IoCallOutline } from "react-icons/io5";
import { FaCarSide } from "react-icons/fa";
import { convertDateToString } from "Pages/Dashboard/helper";

export const CabInfo = ({ data, bookingId, addClass }) => {
  return (
    <div className={`cabDetailsContainer ${addClass}`}>
      <div className="bookingId">
        Booking ID: <span>{bookingId?.toUpperCase()}</span>
      </div>
      <div className="cabDetails">
        <div className="cabNumber">
          <b>{data?.vehicle_number}</b>
        </div>
        <div className="journeyDate">
          {convertDateToString(data?.journey_date)}
        </div>
      </div>

      <div className="cabTravelInfo">
        <div className="from">{data?.from}</div>
        <div>
          <FaCarSide className="icon" />
        </div>
        <div className="to">{data?.to}</div>
      </div>
      {/* 
      <div className="seatInfo">
        <div className="iconContainer">
          Seat Number
          <MdAirlineSeatReclineExtra className="icon" />
        </div>
        <div className="seatNumber">
          {data?.seat_number.split(",").map((seat) => (
            <p key={seat}>{seat}</p>
          ))}
        </div>
      </div> */}

      <div className="seatInfo">
        {/* <div className="iconContainer"> */}
        {/* Passenger Name */}
        {/* <MdOutlineEmojiPeople className="icon" /> */}
        {/* </div> */}
        <div className="seatNumber">
          {data?.passenger_info.map((user) => (
            <p key={user?.name}>{user?.name}</p>
          ))}
        </div>
      </div>

      <div className="cab_contact_info">
        <div className="call_info">
          <a href={`tel:${data?.driver_phone_no}`}>
            <IoCallOutline className="icon" />
          </a>
          <div>
            <span>Call Cab</span>
          </div>
        </div>
      </div>
    </div>
  );
};
