import { createContext, useReducer } from "react";
import PackageReducer from "./Reducer";

const INITIAL_STATE = {
  loading: false,
  error: null,
  packages: [],
  package: null,
  userPackageStats: null,
  userPackages: null,
};

export const PackageContext = createContext(INITIAL_STATE);

export const PackageContextProvider = ({ children }) => {
  const [packageState, packageDispatch] = useReducer(
    PackageReducer,
    INITIAL_STATE
  );

  return (
    <PackageContext.Provider
      value={{
        packageDispatch,
        packageLoading: packageState.loading,
        packageError: packageState.error,
        packages: packageState.packages,
        package: packageState.package,
        userPackages: packageState.userPackages,
        userPackageStats: packageState.userPackageStats,
      }}
    >
      {children}
    </PackageContext.Provider>
  );
};
