import React, { useContext, useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { AiOutlineMail, AiOutlineLogin } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import Page from "components/Page";
import { AuthContext } from "Context/Auth";
import "./auth.css";
import { LoaderText } from "components/LoaderText";
// import { Link } from "react-router-dom";

export const LoginPage = () => {
  const alert = useAlert();
  const { auth, loginUser } = useContext(AuthContext);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (auth?.error) {
      alert.show(auth?.error.replace("Payload", "Credentials"), {
        type: "error",
        timeout: 5000,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const handleChange = (e) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const handleLogin = () => {
    loginUser(loginData.email, loginData.password);
  };

  return (
    <Page title="Login User | Tour Packages | Honeymoon Packages | Group Tours | Corporate Trips | Homestays | Solo Trips">
      <section className="login section">
        <div className="loginContainer">
          <div className="loginContent">
            <div className="imgcontainer">
              <img
                src="https://raw.githubusercontent.com/anujsinghwd/banjarey-images/main/0001-3352367735_20210624_123322_0000-removebg-preview.png"
                alt="Avatar"
                className="avatar"
              />
            </div>
            <div className="inputcontainer">
              <div className="input flex">
                <input
                  type="text"
                  placeholder="Enter Email Here...."
                  name="email"
                  value={loginData.email}
                  onChange={handleChange}
                  required
                />
                <AiOutlineMail className="icon" />
              </div>

              <div className="input flex">
                <input
                  type="password"
                  placeholder="Enter Password Here..."
                  name="password"
                  value={loginData.password}
                  onChange={handleChange}
                  required
                />
                <RiLockPasswordLine className="icon" />
              </div>
              {/* <div className="forgot">
                <RiLockPasswordLine />
                <Link to="/" className="forgot-password-link">Forgot Password?</Link>
              </div> */}
              <button disabled={auth.authLoading} onClick={handleLogin}>
                {" "}
                {auth?.authLoading ? <LoaderText /> : `Login`}
                {!auth?.authLoading && <AiOutlineLogin className="icon" />}
              </button>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
};
