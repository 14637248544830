import React, { useContext, useEffect } from "react";
import Page from "components/Page";
import { AuthContext } from "Context/Auth";
import { PackageContext } from "Context/Package";
import { Link } from "react-router-dom";
import { getUserPackageStats } from "services";
import {
  GET_USER_PACKAGE_STAT_FAILED,
  GET_USER_PACKAGE_STAT_START,
  GET_USER_PACKAGE_STAT_SUCCESS,
} from "constants/reducer-types";

import "./dashboard.css";
import { quotes } from "constants/quotes";
import { Loader } from "components/Loader/Loader";

export const DashboardPage = () => {
  const { cookies } = useContext(AuthContext);
  const { userPackageStats, packageDispatch, packageLoading } =
    useContext(PackageContext);

  const getStats = async () => {
    packageDispatch({ type: GET_USER_PACKAGE_STAT_START });
    const { data, error } = await getUserPackageStats();
    if (data) {
      packageDispatch({
        type: GET_USER_PACKAGE_STAT_SUCCESS,
        payload: data?.data?.data,
      });
    }
    if (error) {
      packageDispatch({ type: GET_USER_PACKAGE_STAT_FAILED, payload: error });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { name } = cookies?.user;
  const checkCondition = [null, 0, undefined];
  const isInprogressExist = checkCondition.includes(
    userPackageStats?.IN_PROGRESS?.count
  );
  const isUpcomingExist = checkCondition.includes(
    userPackageStats?.UPCOMING?.count
  );
  const isCompletedExist = checkCondition.includes(
    userPackageStats?.COMPLETED?.count
  );

  const quoteNo = (Math.random() * 18) | 0;

  return (
    <Page title="User Dashboard | Tour Packages | Honeymoon Packages | Group Tours | Corporate Trips | Homestays | Solo Trips">
      <section className="dashboard section">
        {packageLoading ? (
          <div className="loaderConfig">
            <Loader />
          </div>
        ) : (
          <div className="dashboardContainer">
            <div className="title">
              <h3>Welcome, {name}</h3>
              <q className="quote">{quotes[quoteNo]}</q>
            </div>
            <div className="dashboardContent">
              <div
                className={`dashboardCard ${isInprogressExist && "disabled"}`}
              >
                <Link to="in_progress" className="data-card">
                  <h3>{userPackageStats?.IN_PROGRESS?.count || 0}</h3>
                  <h4>ON GOING TRIPS</h4>
                  <span
                    className={`link-text ${
                      isInprogressExist && "disabledText"
                    }`}
                  >
                    View All
                  </span>
                </Link>
              </div>

              <div className={`dashboardCard ${isUpcomingExist && "disabled"}`}>
                <Link to="upcoming" className="data-card">
                  <h3>{userPackageStats?.UPCOMING?.count || 0}</h3>
                  <h4>UPCOMING TRIPS</h4>
                  <span
                    className={`link-text ${isUpcomingExist && "disabledText"}`}
                  >
                    View All
                  </span>
                </Link>
              </div>

              <div
                className={`dashboardCard ${isCompletedExist && "disabled"}`}
              >
                <Link to="completed" className="data-card">
                  <h3>{userPackageStats?.COMPLETED?.count || 0}</h3>
                  <h4>PAST TRIPS</h4>
                  <span
                    className={`link-text ${
                      isCompletedExist && "disabledText"
                    }`}
                  >
                    View All
                  </span>
                </Link>
              </div>
            </div>
          </div>
        )}
      </section>
    </Page>
  );
};
