export const GET_PACKAGES_START = 'GET_PACKAGES_START';
export const GET_PACKAGES_SUCCESS = 'GET_PACKAGES_SUCCESS';
export const GET_PACKAGES_FAILED = 'GET_PACKAGES_FAILED';

export const GET_PACKAGE_START = 'GET_PACKAGE_START';
export const GET_PACKAGE_SUCCESS = 'GET_PACKAGE_SUCCESS';
export const GET_PACKAGE_FAILED = 'GET_PACKAGE_FAILED';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const AUTO_LOGIN = 'AUTO_LOGIN';

export const GET_USER_PACKAGE_STAT_START = 'GET_USER_PACKAGE_STAT_START';
export const GET_USER_PACKAGE_STAT_SUCCESS = 'GET_USER_PACKAGE_STAT_SUCCESS';
export const GET_USER_PACKAGE_STAT_FAILED = 'GET_USER_PACKAGE_STAT_FAILED';

export const GET_USER_PACKAGE_START = 'GET_USER_PACKAGE_START';
export const GET_USER_PACKAGE_SUCCESS = 'GET_USER_PACKAGE_SUCCESS';
export const GET_USER_PACKAGE_FAILED = 'GET_USER_PACKAGE_FAILED';

export const SUBMIT_INQUIRY_START = 'SUBMIT_INQUIRY_START';
export const SUBMIT_INQUIRY_SUCCESS = 'SUBMIT_INQUIRY_SUCCESS';
export const SUBMIT_INQUIRY_FAILED = 'SUBMIT_INQUIRY_FAILED';
