import * as CryptoJS from "crypto-js";

const { REACT_APP_ENCRYPTION_KEY } = process.env;

export const encryptString = (text) => {
    return CryptoJS.AES.encrypt(text, REACT_APP_ENCRYPTION_KEY).toString();
}

export const objectEncryption = (reqObj) => {
    return CryptoJS.AES.encrypt(JSON.stringify(reqObj), REACT_APP_ENCRYPTION_KEY).toString();
}
