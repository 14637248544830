import { createContext, useReducer } from "react";
import AppReducer from "./Reducer";

const INITIAL_STATE = {
  loading: false,
  error: null,
};

export const AppContext = createContext(INITIAL_STATE);

export const AppContextProvider = ({ children }) => {
  const [appState, appDispatch] = useReducer(AppReducer, INITIAL_STATE);

  return (
    <AppContext.Provider
      value={{
        appDispatch,
        appLoading: appState.loading,
        appError: appState.error,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
