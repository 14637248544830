import axios from "axios";
const { REACT_APP_USER_SERVICES_BASE_URL } = process.env;

export const loginUser = async (email, password) => {
  const resp = {
    data: null,
    error: null,
  };

  const reqPayload = {
    email,
    password,
  };

  try {
    const respData = await axios.post(
      `${REACT_APP_USER_SERVICES_BASE_URL}/btp-users/login`,
      reqPayload
    );
    resp.data = respData?.data;
  } catch (err) {
    resp.error = err;
  }
  return resp;
};
