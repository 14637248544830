import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { PackageContext } from "Context/Package";
import { getUserPackageStats, getUserPackages } from "services";
import {
  GET_USER_PACKAGE_STAT_FAILED,
  GET_USER_PACKAGE_STAT_START,
  GET_USER_PACKAGE_STAT_SUCCESS,
  GET_USER_PACKAGE_SUCCESS,
  GET_USER_PACKAGE_FAILED,
  GET_USER_PACKAGE_START,
} from "constants/reducer-types";

import { calculateNights, convertDateToString } from "Pages/Dashboard/helper";

// import booking_1 from "Assets/booking/booking-1.jpeg";
// import booking_2 from "Assets/booking/booking-2.jpeg";
// import booking_3 from "Assets/booking/booking-3.jpeg";
// import booking_4 from "Assets/booking/booking-4.jpeg";

// import history from "custom-history";

import "./bookinghistory.css";
import { BookingDetails } from "Pages/Dashboard/BookingDetails/BookingDetails";
import { Loader } from "components/Loader/Loader";

export const BookingHistory = () => {
  const location = useLocation();
  const currentStatus = location.pathname.split("/")[3].toUpperCase();

  const { userPackageStats, packageDispatch, userPackages, packageLoading } =
    useContext(PackageContext);

  const [toggleModal, setToggleModal] = useState({
    type: null,
    show: false,
  });
  const [currentRow, setCurrentRow] = useState(null);

  const getStats = async () => {
    packageDispatch({ type: GET_USER_PACKAGE_STAT_START });
    const { data, error } = await getUserPackageStats();
    if (data) {
      packageDispatch({
        type: GET_USER_PACKAGE_STAT_SUCCESS,
        payload: data?.data?.data,
      });
    }
    if (error) {
      packageDispatch({ type: GET_USER_PACKAGE_STAT_FAILED, payload: error });
    }
  };

  const getPackagesList = async () => {
    packageDispatch({ type: GET_USER_PACKAGE_START });
    const { data, error } = await getUserPackages(
      userPackageStats[currentStatus]?.ids
    );
    if (data) {
      packageDispatch({
        type: GET_USER_PACKAGE_SUCCESS,
        payload: data?.data?.data,
      });
    }
    if (error) {
      packageDispatch({ type: GET_USER_PACKAGE_FAILED, payload: error });
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    if (!userPackageStats) {
      getStats();
    }
    getPackagesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPackageStats]);

  const hideDetailsModal = () => {
    setCurrentRow(null);
    setToggleModal({ ...toggleModal, show: false, type: null });
  };

  const handleOpenModal = (info, type = null) => {
    setCurrentRow(info);
    setToggleModal({ ...toggleModal, show: true, type });
  };

  return (
    <>
      <section className="bookingHistory section">
        <div className="bookingHistoryContainer">
          {packageLoading ? (
            <div className="loaderConfig">
              <Loader />
            </div>
          ) : (
            <div className="bookingsListContainer grid">
              {userPackages?.length > 0 &&
                userPackages?.map((packages, Idx) => {
                  return (
                    <div className="bookingList flex" key={Idx}>
                      <div className="generalInfo">
                        <div className="title">{packages?.location}</div>
                        <div className="date">
                          {convertDateToString(packages?.package_booking_date)}
                        </div>
                        <div className="nights">
                          {" "}
                          {convertDateToString(
                            packages?.journey_date
                          ).substring(0, 10)}{" "}
                          <span className="text">
                            -
                            {calculateNights(
                              packages?.journey_date,
                              packages?.return_date
                            )}{" "}
                            N-
                          </span>
                          {convertDateToString(packages?.return_date).substring(
                            0,
                            10
                          )}
                        </div>
                        <div className="btnContainer">
                          {packages?.hotel_info?.length > 0 && (
                            <button
                              onClick={() => handleOpenModal(packages, "HOTEL")}
                              className="btn"
                              disabled={!(packages?.hotel_info?.length > 0)}
                            >
                              Hotel
                            </button>
                          )}
                          {packages?.bus_info?.length > 0 && (
                            <button
                              onClick={() => handleOpenModal(packages, "BUS")}
                              className="btn"
                              disabled={!(packages?.bus_info?.length > 0)}
                            >
                              Bus
                            </button>
                          )}
                          {packages?.cab_info?.length > 0 && (
                            <button
                              onClick={() => handleOpenModal(packages, "CAB")}
                              className="btn"
                              disabled={!(packages?.cab_info?.length > 0)}
                            >
                              Cab
                            </button>
                          )}
                          {packages?.camp_info?.length > 0 && (
                            <button
                              onClick={() => handleOpenModal(packages, "CAMP")}
                              className="btn"
                              disabled={!(packages?.camp_info?.length > 0)}
                            >
                              Camp
                            </button>
                          )}
                        </div>
                      </div>
                      {/* <div className="imageContainer">
                      <img src={booking_1} alt="randomImage" />
                    </div> */}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </section>
      {toggleModal.show && toggleModal.type && (
        <BookingDetails
          packageDetails={currentRow}
          handleClose={hideDetailsModal}
          modalType={toggleModal.type}
        />
      )}
    </>
  );
};
