import React, { useEffect } from "react";
import Page from "components/Page";
import "./about.css";
import Aos from "aos";
import "aos/dist/aos.css";

export const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <Page title="About Us | Honeymoon Packages | Group Tours | Corporate Trips | Homestays | Solo Trips">
      <section className="about section">
        <div className="aboutTitle">
          <h2>About Company</h2>
        </div>

        <div data-aos="fade-up" className="aboutContainer">
          <div className="imageSection">
            <img
              src="https://raw.githubusercontent.com/anujsinghwd/banjarey-images/main/IMG-20210123-WA0056.jpeg"
              alt="aboutImageone"
            />
          </div>

          <article>
            <h3>When we started</h3>
            <p>
              At the beginning Banjarey was meant just to guide bike travellers,
              fulfilling their journey needs and spreading awareness about Royal
              Enfield bullets. In short Banjarey meant to be just a consultancy
              service, but due to our tenacity to do something in traveling
              sector we started taking trips and rest is history. From October
              2020 till now we have 500+ satisfied travellers, 120+ hotels, 80+
              camps and 50+ homestays across India and the number is still
              counting. We aim to provide the best service at your budget
              because all that matters to us is a happy client.
            </p>
          </article>
        </div>

        <div  data-aos="fade-up" className="aboutContainer">
          <div className="imageSection">
            <img
              src="https://raw.githubusercontent.com/anujsinghwd/banjarey-images/main/IMG-20210125-WA0119.jpeg"
              alt="aboutImageTwo"
            />
          </div>
          <article>
            <h3>Why Banjarey ?</h3>
            <p>
              In the hard time of COVID-19 pandemic when almost every life was
              at a halt and the worst hit industry was the travelling industry.
              Slow business was not the biggest concern for us but safe
              travelling was. Keeping customer's health in mind we ensured that
              all our vehicles, camps and hotels were completely sanitised, all
              the staff was maintaining each preventive measure and guiding the
              tourists to do so. In all the thicks and thins we managed safe
              travelling and zero fatality.
            </p>
          </article>
        </div>

        <div  data-aos="fade-up" className="aboutContainer last">
          <div className="imageSection">
            <img
              src="https://raw.githubusercontent.com/anujsinghwd/banjarey-images/main/IMG-20210126-WA0098.jpeg"
              alt="aboutImageone"
            />
          </div>
          <article>
            <h3>We are family</h3>
            <p>
              We at Banjarey are keen to maintain societal values along with the
              free spirit of the youth. As much as our lives are important,
              fulfilling it with enthusiasm, adventure and jolly memories is
              important too. So join Banjarey Travel Partner on your next trip
              and embrace friendships, love, family, self love, togetherness,
              marriage and much more at your budget.
            </p>
          </article>
        </div>
      </section>
    </Page>
  );
};
