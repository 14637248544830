import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import landing_image from "Assets/landing_image.jpeg";
import { FiSend } from "react-icons/fi";
import { FiChevronRight } from "react-icons/fi";
import { FiFacebook } from "react-icons/fi";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";

import Aos from "aos";
import "aos/dist/aos.css";

import "./footer.css";

const Footer = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const currentYear = new Date().getFullYear();
  return (
    <section className="footer">
      <div className="imageDiv">
        <img src={landing_image} alt="" />
      </div>

      <div className="secContent container">
        <div className="contactDiv flex">
          <div data-aos="fade-up" className="text">
            <small>KEEP IN TOUCH</small>
            <h2>Travel with us</h2>
          </div>

          <div className="inputDiv flex">
            <input
              data-aos="fade-up"
              type="text"
              placeholder="Enter Email Address"
            />
            <button data-aos="fade-up" className="btn flex">
              SEND <FiSend className="icon" />
            </button>
          </div>
        </div>

        <div className="footerCard flex">
          <div className="footerIntro flex">
            <div className="logoDiv">
              <Link to="#" className="logo flex">
                {/* <MdOutlineTravelExplore className="icon" /> */}
                <img
                  className="icon"
                  src="https://raw.githubusercontent.com/anujsinghwd/banjarey-images/main/0001-3352367735_20210624_123322_0000-removebg-preview.png"
                  alt="logo"
                />
                <span className="icon-with-text">Banjarey Travel Partner</span>
              </Link>
            </div>

            <div data-aos="fade-up" className="footerParagraph">
              Banjarey travel Partner We at Banjarey are keen to maintain
              societal values along with the free spirit of the youth. As much
              as our lives are important, fulfilling it with enthusiasm,
              adventure and jolly memories is important too. So join Banjarey
              Travel Partner on your next trip and embrace friendships, love,
              family, self love, togetherness, marriage and much more at your
              budget.
            </div>

            <div data-aos="fade-up" className="footerSocials flex">
              <FiFacebook
                className="icon"
                onClick={() =>
                  window.open(`https://www.facebook.com/banjarey2020/`)
                }
              />
              <AiOutlineInstagram
                className="icon"
                onClick={() =>
                  window.open(`https://www.instagram.com/__banjarey__`)
                }
              />
              <AiFillYoutube
                className="icon"
                onClick={() =>
                  window.open(
                    `https://www.youtube.com/channel/UCtLZFSPJQ_T-Byhh56FV78Q`
                  )
                }
              />
            </div>
          </div>

          <div className="footerLinks grid">
            {/* Group Links - First Group - Starts Here */}
            <div
              data-aos="fade-up"
              data-aos-duration="4000"
              className="linkGroup"
            >
              <span className="groupTitle">QUICK LINKS</span>

              <Link to="/tours" className="footerList flex">
                <FiChevronRight className="icon" />
                Tour Packages
              </Link>

              <Link to="/about" className="footerList flex">
                <FiChevronRight className="icon" />
                About Us
              </Link>

              <Link to="/contact" className="footerList flex">
                <FiChevronRight className="icon" />
                Contact Us
              </Link>

              <Link to="terms-and-condition" className="footerList flex">
                <FiChevronRight className="icon" />
                Terms and Condition
              </Link>
            </div>
            {/* Group Links - First Group - Ends Here */}
          </div>

          <div className="footerDiv flex">
            <small>BANJAREY TRAVEL PARTNER</small>
            <small>COPYRIGHT © {currentYear} BANJAREY TRAVEL PARTNER</small>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
