import React from "react";
import Slider from "react-slick";

import { AiOutlineInstagram } from "react-icons/ai";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./reviews.css";
import { items } from "./ReviewsData";

export const Reviews = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    speed: 3500,
    swipeToSlide: true,
    autoplaySpeed: 300,
    initialSlide: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 465,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          dots: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          dots: false,
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div style={{ marginBottom: "30px" }}>
      <Slider {...settings}>
        {items.map((data, Idx) => (
          <div key={Idx}>
            <div className="reviewsCard">
              <img src={data.image} alt="profile-sample2" />
              <figcaption>
                <h3
                  onClick={() =>
                    window.open(`https://instagram.com/${data.instaid}`)
                  }
                >
                  {data.name} <AiOutlineInstagram className="icon" />
                </h3>
                <blockquote>
                  <p>{data.testimony}</p>
                </blockquote>
              </figcaption>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
