import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { getTour } from "services";
import { PackageContext } from "Context/Package";
import {
  GET_PACKAGE_START,
  GET_PACKAGE_FAILED,
  GET_PACKAGE_SUCCESS,
} from "constants/reducer-types";
import { GrLocationPin } from "react-icons/gr";
import { AiOutlineClockCircle } from "react-icons/ai";
import { TiTickOutline } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";

import { TbCurrencyRupee } from "react-icons/tb";
import "./tour-details.css";
import Page from "components/Page";

import Aos from "aos";
import "aos/dist/aos.css";
import { Loader } from "components/Loader/Loader";

export const TourDetails = () => {
  const { package: currentPackage, packageDispatch, packageLoading } =
    useContext(PackageContext);
  const location = useLocation();
  const currentId = location.pathname.split("/")[2];

  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    async function fetchData() {
      packageDispatch({ type: GET_PACKAGE_START });
      const { data, error } = await getTour(currentId);
      if (data) {
        packageDispatch({ type: GET_PACKAGE_SUCCESS, payload: data?.data });
      }
      if (error) {
        packageDispatch({ type: GET_PACKAGE_FAILED, payload: error });
      }
    }
    if (currentId) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentId]);

  return (
    <Page title={currentPackage?.title || "Tour Package Details"}>
      {packageLoading ? (
        <div style={{paddingTop: '10rem'}} className="loaderConfig">
          <Loader />
        </div>
      ) :(<div className="tour">
        <div className="tourInfo">
          <div className="tourTitle">
            <GrLocationPin className="icon" />
            {currentPackage?.title}
          </div>

          <div className="tourLocations">
            <b>Locations: </b>
            {currentPackage?.destinations}
          </div>

          {currentPackage?.additional_services && (
            <div className="tourAdditionalService">
              <b>Additional Service: </b>
              {currentPackage?.additional_services}
            </div>
          )}

          {currentPackage?.note && (
            <div className="tourAdditionalService">
              <b>Note: </b>
              {currentPackage?.note}
            </div>
          )}

          <div className="durationPriceContainer">
            <div className="durationContainer">
              <div className="key">Duration</div>
              <div className="value">
                <AiOutlineClockCircle />
                {currentPackage?.duration}
              </div>
            </div>
            <div className="divider"></div>
            <div className="priceContainer">
              <div className="key">Starting From</div>
              <div className="value">
                <TbCurrencyRupee />
                {currentPackage?.starting_range}
              </div>
            </div>
          </div>
        </div>

        <h3 className="heading">Package Itinerary</h3>

        <div className="tourDetailContainer">
          <ul>
            {currentPackage &&
              currentPackage?.itinerary?.length > 0 &&
              currentPackage?.itinerary?.map((val, Idx) => {
                return (
                  <li key={Idx}>
                    <h3 className="title">{val?.subtitle}</h3>
                    <p>{val?.info}</p>
                    <span className="circle"></span>
                    <span className="date">{`Day ${Idx + 1}`}</span>
                  </li>
                );
              })}
          </ul>
        </div>

        <div className="inclusionExclusionContainer">
          <div className="inclusionContainer">
            <div className="inclusionTitle">Included</div>
            <div className="includedList">
              {currentPackage?.inclusions?.length > 0 &&
                currentPackage?.inclusions?.map((p, Idx) => {
                  return (
                    <div key={Idx} className="includeListing">
                      <TiTickOutline />
                      {p}
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="inclusionContainer">
            <div className="inclusionTitle">Excluded</div>
            <div className="includedList">
              {currentPackage?.exclusions?.length > 0 &&
                currentPackage?.exclusions?.map((p, Idx) => {
                  return (
                    <div key={Idx} className="includeListing">
                      <RxCross2 style={{background: '#F75D59'}} />
                      {p}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>)}
    </Page>
  );
};
