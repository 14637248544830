import React, { useContext, useEffect } from "react";
import Main from "components/Main/Main";
import Page from "components/Page";
import { PackageContext } from "Context/Package";
import {
  GET_PACKAGES_START,
  GET_PACKAGES_SUCCESS,
  GET_PACKAGES_FAILED,
} from "constants/reducer-types";
import { readVisitedDestinations } from "services";

import "./tour.css";
import { Loader } from "components/Loader/Loader";

export const TourPage = () => {
  const { packages, packageDispatch, packageLoading } = useContext(PackageContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!packages?.length) {
      async function fetchData() {
        packageDispatch({ type: GET_PACKAGES_START });
        const { data, error } = await readVisitedDestinations();
        if (data) {
          packageDispatch({ type: GET_PACKAGES_SUCCESS, payload: data?.data });
        }
        if (error) {
          packageDispatch({ type: GET_PACKAGES_FAILED, payload: error });
        }
      }
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packages]);

  return (
    <Page title="Tour Packages | Honeymoon Packages | Group Tours | Corporate Trips | Homestays | Solo Trips">
      <section className="tour section">
        <div className="tourContainer">
        {packageLoading ? (
          <div style={{marginTop: '8rem'}} className="loaderConfig">
            <Loader />
          </div>
        ) : (
          <Main listData={packages} title="Tour Packages" />
        )}
        </div>
      </section>
    </Page>
  );
};
