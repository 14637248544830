export const items = [
  {
    'key': 0,
    'testimony':"Banjarey travel partner is the best travel company you can ever go for. Trust me they are not a company but you will feel like you are going with your own family and friends. Specially Ankit jii The care he take specially for girls since it's a crucial point as to how girls are treated on an overall journey and trust me all over the trip and importantly the services they provide - trust me you won't even feel like going back. So recommending this because i have been to a place where i have felt the worst experience with one of the other travel company but then BANJAREY TRAVEL PARTNER came to my suggestions from one of my friend and i am so lucky that i went with them and now its been a family with the strangers i went! and Ankit ,anchala ,shweta , niharika from banjarey Go for it people and it's worth every moment!😍😘 ❤️❤️❤️",
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/niharika_mishra.jpeg",
    'name':"Niharika Mishra",
    'work':"Designer",
    'instaid':"____brown_girl____",
    'starRating':5,
    'data-value':"1",
  },
  {
    'key':1,
    'testimony':"It was wonderful experience. Extremely satisfied .very professional,very secure nd reliable...specialy thanks to ankit hi..hope to use your service in future too.",
    'image': "https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/ahana.png",
    'name':"Aahana mishra",
    'work':"Freelancer",
    'instaid':"aahana.mishra.90281",
    'starRating':5,
    'data-value':"2",
  },
  {
    'key':2,
    'testimony':"Amazing trip safety is priority which is great! They are just a call away for anything you want! 100% recommend for future trips",
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/srishti.jpeg",
    'name':"Srishti Chaudhary",
    'work':"Designer",
    'instaid':"srishtich_",
    'starRating':5,
    'data-value':"3",
  },
  {
    'key':3,
    'testimony':"We had a very good experience with banjarey tours and travels.services provided by them were very good the driver was also good and helpfull.I had a very good experience with banjarey as they provide customize package also.",
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/smaridihi_rawat.jpeg",
    'name':"Samradhi raut",
    'work':"Designer",
    'instaid':"drama_queensamu19",
    'starRating':5,
    'data-value':"4",
  },
  {
    'key':4,
    'testimony':"The best trip I've ever had 😍 I'm glad that I choose Banjarey Travel Partners as my trip planners 😉👍 Go for it every one 💯💌'",
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/niharika_sagar.jpeg",
    'name':"Neharika sagar",
    'work':"Designer",
    'instaid':"_neharika",
    'starRating':5,
    'data-value':"5",
  },
  {
    'key':5,
    'testimony':'"We just came back from ooty... the tour was wonderful all along the way, and everything went smooth ... it was extremely well organized. We apprecitate all your  help and efforts in organizing this trip - a truely memmorable adventure!" Absolutely - definitely - I will be recommending your tour and I will also be taking another in the future - Thank you for a wonderful journey! " "I was so pleased with the trip. Our guide and driver were outstanding : knowledgeable, friendly, safety-conscious, and had great sense of humor. Thanks a lot..."',
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/rupesh.jpeg",
    'name':"Rupesh",
    'work':"Designer",
    'instaid':"",
    'starRating':5,
    'data-value':"6",
  },
  {
    'key':6,
    'testimony':"Great service n hospitality .👍... very honest towards their commitment ... keep doing good Work 🤗",
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/mayank.jpeg",
    'name':"Mayank Mishra",
    'work':"Designer",
    'instaid':"mayank05252",
    'starRating':5,
    'data-value':"7",
  },
  {
    'key':7,
    'testimony':"We just returned from our Trip to Uttarakhand Without Banjare tour planner This trip would have been incompleted. Our tour guide who named Ankit was really patient and understand that people from different cultures and background would extra time to absorb all information about history of Uttarakhand We did many things from the unbelievably structured waterfalls on top of mountains, camps, night market, old market and so many more. Apart from all that tourist majority places, he also took us at night to experience the local scene,Everything was perfect and perfectly-arranged. We would recommend this tour service to anyone visiting anywhere in India. he will arrange beautiful trip.",
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/ravi.jpeg",
    'name':"Ravi Pratap",
    'work':"Designer",
    'instaid':"Ravi060729",
    'starRating':5,
    'data-value':"8",
  },
  {
    'key':8,
    'testimony':"Great people and very professional !! Highly recommend their services 😀",
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/badal.jpeg",
    'name':"Badal",
    'work':"Designer",
    'instaid':"Badkwl",
    'starRating':5,
    'data-value':"9",
  },
  {
    'key':9,
    'testimony':`Hello Team Banjarey,Thanks for planning our trip to Manali, Solang and Kasol from Feb 19th - 25th Feb 2021.,
    We truely had expirenced a wonderful, fun filled, relaxing trip where our adventure and exictment level never dropped a bit.
    The itenary and planning was upto to the mark, hotel location, views, food, cleanliness, staff behaviour was seriously awesome and overall let us to be carefree and enjoy without involving in any planning and travelling hassle.
    Being a human I know we all are prone to do some mistake but then also I can’t think of a time when I have regretted planning and booking our trip with you guyz.
    Hope to take advantage and benefit from your wonderful service again in future (with some more discount as well 😅😜)
    Cheers Team Banjarey !!`,
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/abhishek.jpeg",
    'name':"Abhishek Agarwal",
    'work':"Designer",
    'instaid':"agarwalabhishek_",
    'starRating':5,
    'data-value':"10",
  },
  {
    'key':10,
    'testimony':"Awesome Experience.... Its Totally Safe For Girls Also.... Taking Bunch Of Memories With Us... Thank You So Much Banjarey",
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/rachna.jpeg",
    'name':"Rachna",
    'work':"Designer",
    'instaid':"raccho98",
    'starRating':5,
    'data-value':"11",
  },
  {
    'key':11,
    'testimony':"So much cooperative person i will definitely recommend",
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/sakhi_sobhi.jpeg",
    'name':"Shobhana",
    'work':"Designer",
    'instaid':"Shahi_shobhi",
    'starRating':5,
    'data-value':"12",
  },
  {
    'key':12,
    'testimony':"Banjarey Travel Partners gives great services, we had an amazing experience all together from taxi services to food to accomodation and travel, everything was perfect 👌🏻🤩 We all loved your service, thanks for making our trip a memorable one 🙏🏻☺️❣️",
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/ashita_new.png",
    'name':"Ashita gaur",
    'work':"Designer",
    'instaid':"exquisite_ashi16",
    'starRating':5,
    'data-value':"13",
  },
  {
    'key':13,
    'testimony':"I will really recommend all specially Girls to travel with Banjarey. Hotel which they provide was good, clean and safe. food was delicious and provided on time. Ankit was very attentive, responsive and available for us 24*7. Looking forward for one more exciting trip with Banjarey.",
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/Kadambari.jpeg",
    'name':"Kadambari throat",
    'work':"Designer",
    'instaid':"Kadambari_267",
    'starRating':5,
    'data-value':"14",
  },
  {
    'key':14,
    'testimony':"Vry nyc service and ur all work are also so best . This trip was memorable because of you ..😍😍❤️❤️ .. Thnku.",
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/Ashwin.jpeg",
    'name':"Ashwin kumar",
    'work':"Designer",
    'instaid':"ashwin25.09.00",
    'starRating':5,
    'data-value':"15",
  },
  {
    'key':15,
    'testimony':`Traveling is not something You're good At ...it's Something You Do It Like Breathing...." If you really explore the World ...I Suggest You For " banjare Travel Partners " .... They Have Great Services with humbleness ....I m Great Fan of Their Hospitalities .... Keep Growing Banjarey .... ❤️`,
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/Kritika.jpeg",
    'name':"Kritika pandey",
    'work':"Designer",
    'instaid':"Kritikapandey481",
    'starRating':5,
    'data-value':"16",
  },
  {
    'key':16,
    'testimony':`It was wonderful trip to Manali. It was a great experience. I will ask them again, when I planned new trip next time.`,
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/Ankit_sharma.jpeg",
    'name':"Ankit Sharma",
    'work':"Designer",
    'instaid':"",
    'starRating':5,
    'data-value':"17",
  },
  {
    'key':17,
    'testimony':`First of thanks to Banjarey Travels for organizing such a beautiful trip and time to time support during the trip. We would recommend all to Banjarey Travel for any kind of adventures trip. Thanks to Banjarey.`,
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/Bhupendra.jpeg",
    'name':"Bhupendra kumar",
    'work':"Designer",
    'instaid':"",
    'starRating':5,
    'data-value':"18",
  },
  {
    'key':18,
    'testimony':`Thankyou banjarey for making our trip awesome .it was a great trip because of you guys special thanks to Mr.Ankit and thanku to miss Anchala fr daily feedback.,
    First I was afraid to travel after unlock 2 but every thing was managed by banjarey from e- pass to hotel and cab everything was well managed. I will also like to mention our driver Mr Ashok for his way of explanation and story about the place , I must say he was driver cum Guide during my travel.
    Thankyou banjarey &Ankit jii
    All the best fr future.`,
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/avinash_new.png",
    'name':"Avinash Yadav",
    'work':"Designer",
    'instaid':"",
    'starRating':5,
    'data-value':"19",
  },
  {
    'key':19,
    'testimony':`The way banjarey help you to get best experiences in your trip is very amazing. their priority is to serve you the best as per your requirement abd believe that the result will be the beyond expectations. Great work.`,
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/_abhishek_agrawal.jpeg",
    'name':"Abhishek Agrawal",
    'work':"Designer",
    'instaid':"_abhishek_agrawal",
    'starRating':5,
    'data-value':"20",
  },
  {
    'key':20,
    'testimony':`One of the best Travel Agency I Have seen in the UP. His packages are in very reasonable price Every one can Afford his packages, Specially Students, Middle Class Family. Thank Banjarey for giving us wonderful trip with luxury services.`,
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/Shubham.jpeg",
    'name':"Shubham",
    'work':"Designer",
    'instaid':"official_shubham739",
    'starRating':5,
    'data-value':"21",
  },
  {
    'key':21,
    'testimony':`Very well arranged.,
    Great hospitality.
    Promptness towards customers demands.
    Great experience.
    Must travel with them.`,
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/Priyanka.mehta01.png",
    'name':"Priyanka Mehta",
    'work':"Designer",
    'instaid':"Priyanka.mehta01",
    'starRating':5,
    'data-value':"22",
  },
  {
    'key':22,
    'testimony':`So responsive they are moreover being a girl i felt safe with driver ,accommodation and hotel staff everything was just amazing everything was so well organized.... Looking forward for more trips in future 😀😀😀😄😃`,
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/Vaishnavi_lather_2608.png",
    'name':"Vaishnavi Lather",
    'work':"Designer",
    'instaid':"Vaishnavi_lather_2608",
    'starRating':5,
    'data-value':"23",
  },
  {
    'key':23,
    'testimony':`The combination of  walking through the mountains and site seeing , meeting friendly people and staff, enjoyed in warm clear waters and being fed delicious healthy food is a  good company can only be possible with Banjarey 😍It is the best holiday we have ever had with Banjarey Travel Partner 😍❤️Many thanks for your meticulous attention to our comfort. Would love to be part of many more 🤞 🥂,
    Would recommend more👍Go for it and grab your trip🌎`,
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/ankitasharma8808.jpeg",
    'name':"Ankita Sharma",
    'work':"Designer",
    'instaid':"ankitasharma8808",
    'starRating':5,
    'data-value':"24",
  },
  {
    'key':24,
    'testimony':"Got accross with Banjarey on Instagram and planned our first girls trip to Manali with them. It was the best decision ever made, the trip definitely went super success and all the arrangements as well, from hotel stay to Car, everybody was very responsive, they kept on calling in morning and night just to know if everything with us going well or not, solved our every problems very calmly, Highly recommended.👍",
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/laughing._monster.jpeg",
    'name':"Shristi Jaiswal",
    'work':"Designer",
    'instaid':"laughing._monster",
    'starRating':5,
    'data-value':"25",
  },
  {
    'key':25,
    'testimony':"I am glad that we chose banjarey for our first manali trip with my friends. The trip went super smooth and amazing. Every little arrangement was made and they were very responsive and caring. Hotel was beautiful and the view from the balcony was mesmerising. Highly recommended ♥️",
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/iam_ragini.jpeg",
    'name':"Ragini Mishra",
    'work':"Designer",
    'instaid':"iam_ragini",
    'starRating':5,
    'data-value':"26",
  },
  {
    'key':26,
    'testimony':"A happy Banjarey Customer. I planned my trip almost last minute and the team was soooo good they just took us in and gave us a wonderful, affordable and quality package. Keeping aside some helpless ups and downs, they were just gooood. Kept in touch throughout the trip to make us comfortable. My trip to Manali, Rohtang, Solang, Kasol in 3 days..... Was just awesome and all the credit goes to the team. Ankit & Salman, you guys are great and wonderful and from my hometown 😂. I'll make sure all my trips are taken care by Banjarey's Team. I'll travel often now 🔥",
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/himanshi.sharmaa.jpeg",
    'name':"Himanshi Sharma",
    'work':"Designer",
    'instaid':"himanshi.sharmaa",
    'starRating':5,
    'data-value':"27",
  },
  {
    'key':27,
    'testimony':"It was a really wonderful experience with Banjarey, Amazing service by the travel partner and they made us feel homely with their great communication skill. Thank you so much for this wonderful trip. I personally recommend it to everyone.",
    'image':"https://raw.githubusercontent.com/anujsinghwd/banjarey-images-hub/main/clients/paras6454.jpeg",
    'name':"Paras Bulsara",
    'work':"Designer",
    'instaid':"paras6454",
    'starRating':5,
    'data-value':"28",
  },
];